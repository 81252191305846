<template>
  <Modal id="matchModal" class="match-modal -thin" :title="$t('matchModal.title')" :defaultOpen="false">
    <p class="font-weight-medium font-size-s">{{ $t('matchModal.intro') }}</p>

    <Multiselect
      ref="multiselect"
      v-model="selectedLead"
      :options="selectOptions"
      :searchable="true"
      :multiple="false"
      :taggable="false"
      :show-labels="false"
      :custom-label="leadIdWithTitle"
      :close-on-select="true"
      :internal-search="true"
      :hide-selected="true"
      :loading="isLoadingSearch"
      :placeholder="$t('matchModal.select.placeholder')"
      :selectLabel="$t('matchModal.select.enterToSelect')"
      noOptions="asd"
      tag-placeholder=""
      track-by="id"
      @search-change="onSearchChange"
    >
      <template slot="placeholder">{{ '' }}</template>
      <template slot="noOptions">{{ $t('matchModal.select.noOptions') }}</template>
      <template slot="noResult">{{ $t('matchModal.select.noResults') }}</template>
    </Multiselect>

    <p class="mt-m mb-xs font-weight-medium font-size-s">{{ $t('matchModal.lastEditedTitle') }}</p>

    <template v-if="isLoadingLastUpdated">
      <div class="mt-m text-center">
        <Icon>
          <LoaderSVG />
        </Icon>
      </div>
    </template>

    <template v-else-if="leadsLastUpdated.length > 0">
      <ul class="last-leads-list">
        <li v-for="lead in leadsLastUpdated" :key="lead.id" :disabled="isAlreadyMatched(lead)" @click="onSelectLeadFromLastUpdated(lead)">ID{{ lead.custom_id }}: {{ lead.title }}</li>
      </ul>
    </template>

    <template v-slot:footer>
      <div class="text-right">
        <Button class="mr-s -orange -outlined" @click.native="close">
          {{ $t('matchModal.buttons.cancel') }}
        </Button>
        <Button class="-orange" :disabled="!selectedLead || !managerUuid" @click.native="createLeadMatch">
          {{ $t('matchModal.buttons.save') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>
<script>
import debounce from 'debounce';
import Multiselect from 'vue-multiselect';
import Modal from '@/components/elements/Modal.vue';
import { listLeads } from '@/api/leads.api';
import { createMatch } from '@/api/matches.api';
import { listMatches } from '@/api/admins.api';
import { queryObjects } from '@/mixins';
import Button from '@/components/atoms/Button.vue';
import LoaderSVG from '@/assets/icons/loader.svg';
import Icon from '@/components/atoms/Icon.vue';

export default {
  name: 'MatchModal',
  mixins: [queryObjects],
  props: {
    base: String,
  },
  data() {
    return {
      isLoadingLastUpdated: true,
      isLoadingSearch: false,
      managerUuid: null,
      managerMatches: [],
      leads: [],
      selectedLead: null,
      leadsLastUpdated: [],
      searchIn: ['$title$', '$custom_id$'],
      leadsLastUpdatedLimit: 5,
    };
  },
  mounted() {
    this.$eventBus.$on('open-modal-match', ({ managerUuid, managerMatches }) => {
      this.managerUuid = managerUuid;
      this.managerMatches = managerMatches;
      if (!managerMatches) this.fetchManagerMatches();
      this.fetchLeadsLastUpdated();
      this.$eventBus.$emit('open-modal-matchModal');
    });
  },
  beforeUnmount() {
    this.$eventBus.$off('open-modal-match');
  },
  computed: {
    selectOptions() {
      return this.leads.map((lead) => ({
        ...lead,
        $isDisabled: this.isAlreadyMatched(lead),
      }));
    },
  },
  methods: {
    leadIdWithTitle(lead) {
      return `ID${lead.custom_id}: ${lead.title}`;
    },

    fetchLeadsLastUpdated() {
      this.isLoadingLastUpdated = true;

      this.$store.dispatch('isLoggedIn').then((token) => {
        listLeads(this.getSortQuery(), token)
          .then((response) => {
            this.leadsLastUpdated = response.data.data;
            this.isLoadingLastUpdated = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoadingLastUpdated = false;
          });
      });
    },

    getSortQuery() {
      return {
        ...this.buildSortQuery({
          value: 'updatedAt',
          direction: 'desc',
        }),
        limit: this.leadsLastUpdatedLimit,
      };
    },

    onSearchChange: debounce(function cb(query) {
      if (this.managerUuid) this.fetchSearchLeads(query);
    }, 500),

    fetchSearchLeads(query) {
      this.isLoadingSearch = true;

      this.$store.dispatch('isLoggedIn').then((token) => {
        listLeads(this.getSearchQuery(query), token)
          .then((response) => {
            this.leads = response.data.data;
            this.isLoadingSearch = false;
          })
          .catch((e) => {
            console.log(e);
            this.isLoadingSearch = false;
          });
      });
    },

    getSearchQuery(search) {
      const filter = this.getSearchFilter(search);

      return {
        ...this.buildFilterQuery(filter),
      };
    },

    getSearchFilter(search) {
      return {
        query: {
          and: [...(search ? this.buildSearchFilter(search) : [])],
        },
      };
    },

    buildSearchFilter(input) {
      const splitInput = input.split(/[\s,]+/).filter((el) => el.trim().length > 0);

      return splitInput.map((el) => ({
        or: this.searchIn.map((arg) => ({
          [arg]: {
            regexp: `${el}`,
          },
        })),
      }));
    },

    isAlreadyMatched(lead) {
      return this.managerMatches.some((match) => match.lead_id === lead.id);
    },

    onSelectLeadFromLastUpdated(lead) {
      if (this.isAlreadyMatched(lead)) return;
      this.selectedLead = lead;
    },

    createLeadMatch() {
      if (!this.selectedLead || !this.managerUuid) return;

      this.$store.dispatch('isLoggedIn').then((token) => {
        createMatch(this.selectedLead.id, this.managerUuid, token)
          .then(() => {
            this.$eventBus.$emit('notificate', { title: this.$t('notifications.matchModal.success.title'), message: this.$t('notifications.matchModal.success.message') });
            this.$eventBus.$emit('match-modal-created', { manager_uuid: this.managerUuid, lead_id: this.selectedLead.id });
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
          });
      });
    },

    close() {
      this.managerUuid = null;
      this.managerMatches = [];
      this.selectedLead = null;
      this.leads = [];
      this.leadsLastUpdated = [];
      this.$eventBus.$emit('close-modal-matchModal');
    },

    fetchManagerMatches() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          listMatches(this.managerUuid, {}, token)
            .then((r) => r.data)
            .then((data) => {
              this.$set(this, 'managerMatches', data.rows);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    },
  },
  components: {
    Modal,
    Multiselect,
    Button,
    LoaderSVG,
    Icon,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/match-modal.scss"></style>
<style lang="scss" src="@/sass/07_elements/multiselect.scss"></style>
