<template>
  <Modal id="comments" :title="modalTitle" class="comments -thin" @open="loadData" @close="resetOnClose">

    <div class="mb-xs flex">
      <span class="mr-xs">{{ $t('comments.filter') }}</span>
      <Button class="-no-padding mr-xxs" @click.native="$store.commit('comments/resetFilter')">
        <Tag class="-slim" :class="filterByType.length === 0 ? '-blue' : '-grey'">
          {{ $t('comments.show_all') }}
        </Tag>
      </Button>
      <Button
        v-for="commentableType in commentableTypes"
        :key="commentableType"
        class="-no-padding mr-xxs"
        @click.native="$store.commit('comments/toggleFilter', commentableType)"
      >
        <Tag class="-slim" :class="filterByType.includes(commentableType) ? '-blue' : '-grey'">
          {{ $t(`commentableTypes.${commentableType}`) }}
        </Tag>
      </Button>
    </div>

    <template v-if="filteredComments.length > 0">
      <CommentList :comments="descFilteredComments" ref="commentsList">
        <template v-slot="{ comment }">
          <Button v-if="userCan('deleteComment')"
            class="delete -small -grey"
            @click.native="deleteComment(comment.id)"
          >
            <Icon class="ml-xxs -inline -is-left color-blue-900">
              <TrashSVG></TrashSVG>
            </Icon>
          </Button>
        </template>
      </CommentList>
    </template>
    <p v-else class="color-grey-400 mb-s">{{ $t('comments.empty') }}</p>

    <ContentBox v-if="manager && manager.notes" class="-slim comment mb-xs">
      <div class="display-flex justify-content-start mb-xs">
        <strong class="block mr-xs">
          {{ $t('comments.imported') }}
        </strong>
      </div>
      <div class="ProseMirror rich-text" v-html="manager.notes"></div>
    </ContentBox>

    <template slot="footer">
      <FormElement class="mb-s">
        <div class="row">
          <div class="col-24">
            <InputRichText name="content"
                  :class="{ '-has-error': $v.comment.content.$error }"
                  v-model="$v.comment.content.$model"
            ></InputRichText>
          </div>
        </div>
      </FormElement>
      <div class="display-flex">
        <StarRating v-model="comment.rating" :show-rating="false" :star-size="24" :padding="8" active-color="#F5AE2A" inactive-color="#CCD2D7" />
        <div class="align-items-end">
          <Button class="mr-s -orange -outlined" @click.native="$eventBus.$emit('close-modal-comments')">
            {{ $t('comments.buttons.close') }}
          </Button>
          <Button class="-orange" @click.native="saveNewComment">
            {{ $t('comments.buttons.save') }}
          </Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import StarRating from 'vue-star-rating';

import { parseDates, formatStrings } from '@/mixins';
import {
  getComments,
  saveComment,
  deleteComment,
} from '@/api/admins.api';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';
import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import CommentList from '@/components/elements/CommentList.vue';
import FormElement from '@/components/elements/FormElement.vue';
import Modal from '@/components/elements/Modal.vue';
import Icon from '@/components/atoms/Icon.vue';
import Tag from '@/components/atoms/Tag.vue';
import TrashSVG from '@/assets/icons/trash.svg';

export default {
  name: 'Comments',
  mixins: [parseDates, formatStrings, validationMixin],
  validations: {
    comment: {
      author: { required },
      manager: { required },
      commentableType: { required },
      commentableId: { required, numeric },
      content: { required },
      rating: { numeric },
    },
  },
  computed: {
    ...mapGetters([
      'userCan',
    ]),
    ...mapState([
      'usermeta',
    ]),
    ...mapGetters('comments', [
      'filterByType',
    ]),
    modalTitle() {
      if (this.manager && this.manager.User) {
        return this.$t('comments.modal.title', {
          name: `${this.manager.User.firstname} ${this.manager.User.lastname}`,
        });
      }

      return '';
    },
    descFilteredComments() {
      return [...this.filteredComments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    filteredComments() {
      return (this.filterByType.length > 0) ? this.comments.filter((comment) => this.filterByType.includes(comment.commentableType)) : this.comments;
    },
    commentableTypes() {
      return (this.comments.length > 0) ? new Set(this.comments.map((comment) => comment.commentableType)) : [];
    },
  },
  data() {
    return {
      manager: {},
      comments: [],
      comment: {
        author: null,
        manager: null,
        commentableType: '',
        commentableId: null,
        content: '',
        rating: 0,
      },
    };
  },
  methods: {
    async loadData(ev) {
      // TODO: Validate event object
      const { manager, commentableType, commentableId } = ev;

      this.comment.author = this.usermeta.uuid;
      this.comment.manager = manager;
      this.comment.commentableType = commentableType;
      this.comment.commentableId = commentableId;

      this.getComments(manager);
    },
    getComments(manager) {
      return this.$store.dispatch('isLoggedIn')
        .then((token) => getComments(manager, token))
        .then((response) => {
          this.manager = response.data.manager;
          this.comments = response.data.comments;
        });
    },
    saveNewComment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveComment();
      }
    },
    saveComment() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => saveComment(this.manager.uuid, this.comment, token))
        .then((response) => {
          this.comments = [...this.comments, response.data];
          this.comment.content = '';
          this.comment.rating = 0;
          this.$v.$reset();
          this.$emit('update');
          this.$eventBus.$emit('comments-modal-updated');
          this.$eventBus.$emit('notificate');
        })
        .catch((error) => {
          console.error(error);
          this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.data.message });
        });
    },
    deleteComment(commentId) {
      // eslint-disable-next-line
      if (!window.confirm(this.$t('comments.actions.delete'))) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => deleteComment(this.manager.uuid, commentId, token))
        .then(() => {
          this.getComments(this.manager.uuid);
          this.$emit('update');
          this.$eventBus.$emit('notificate');
        })
        .catch((error) => {
          console.error(error);
          this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.data.message });
        });
    },
    scrollLastCommentIntoView() {
      setTimeout(() => {
        const element = this.$el.querySelector('.modal-content > .content-box > .content-box:last-child');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    },
    resetOnClose() {
      this.manager = null;
      this.comments = [];
      this.comment.manager = null;
      this.comment.commentableType = '';
      this.comment.commentableId = null;
      this.comment.rating = 0;
    },
  },
  components: {
    Button,
    ContentBox,
    CommentList,
    FormElement,
    InputRichText,
    Modal,
    StarRating,
    Tag,
    Icon,
    TrashSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/comments.scss"></style>
