<template>
  <main class="home  display-flex">
    <SideBar></SideBar>
    <HeaderBar></HeaderBar>
    <MobileNav v-if="$matchMedia.xs"></MobileNav>
    <div class="main-inner">
      <div class="row  pt-l  pb-xs-l  mb-l  mb-xs-0">
        <div class="col-24">
          <template v-if="$route.name === 'Home' && status === 'self-assessment'">
            <Background></Background>
            <h2 class="h2  mb-m  mt-xs  color-white  font-weight-normal  pos-relative">{{ $t('selfAssessment.welcome.headline') }}</h2>
            <SelfAssessment></SelfAssessment>
          </template>
          <template v-else>
            <div v-if="$route.name === 'Home'">
              <h4>{{ $t('global.hello') }} {{ usermeta.firstname }} {{ usermeta.lastname }}.</h4>
              <p>{{ $t('dashboard.label') }}</p>
            </div>
            <keep-alive :exclude="['ApplicationDetail', 'ProjectDetail', 'UserDetail']">
              <router-view></router-view>
            </keep-alive>
          </template>
        </div>
      </div>
    </div>
    <NotificationBar></NotificationBar>
    <Comments />
    <MatchModal v-if="usermeta.role === 'admin'"></MatchModal>
  </main>
</template>

<script>
import { mapState } from 'vuex';

import NotificationBar from '@/components/elements/NotificationBar.vue';
import HeaderBar from '@/components/modules/HeaderBar.vue';
import SideBar from '@/components/modules/SideBar.vue';
import Comments from '@/components/modules/Comments.vue';
import MatchModal from '@/components/modules/Lead/admin/MatchModal.vue';

export default {
  name: 'Home',
  computed: {
    ...mapState([
      'status',
      'usermeta',
    ]),
  },
  components: {
    NotificationBar,
    HeaderBar,
    SideBar,
    Comments,
    MatchModal,
    SelfAssessment: () => import('@/components/modules/User/application/SelfAssessment.vue'),
    Background: () => import('@/components/atoms/Background.vue'),
    MobileNav: () => import('@/components/modules/MobileNav.vue'),
  },
  metaInfo() {
    return {
      title: this.$t('metaData.home.title'),
      titleTemplate: null,
    };
  },
};
</script>
