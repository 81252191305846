<template>
  <transition name="slide">
    <div :class="['notification-bar', `-${status}`]" v-if="show">
      <div class="_content">
        <Icon :class="[$matchMedia.xs ? '-size-24' : '-size-32']">
          <component :is="`${status}SVG`"></component>
        </Icon>
        <div class="_text">
          <span class="_title">{{ localTitle }}</span>
          <span class="_msg" v-if="message">{{ message }}</span>
        </div>
      </div>
      <div class="_close" @click="handleClose">{{ $t('notificationBar.buttons.close') }}</div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';

export default {
  name: 'NotificationBar',
  data() {
    return {
      statusCode: 200,
      title: false,
      message: false,
      show: false,
      timeout: false,
      showTime: 5000,
    };
  },
  computed: {
    status() {
      return (this.statusCode === 200) ? 'success' : 'error';
    },

    localTitle() {
      return this.title || this.$t(`notificationBar.${this.status}.title`);
    },
  },
  created() {
    this.$eventBus.$on('notificate', this.handleNotification);
  },
  methods: {
    handleNotification(event = {}) {
      this.statusCode = event.status || 200;
      this.title = event.title || false;
      this.message = event.message || false;

      this.show = true;
      clearTimeout(this.timeout);

      if (this.statusCode === 200) {
        this.timeout = setTimeout(() => {
          this.show = false;
        }, this.showTime);
      }
    },

    handleClose() {
      this.show = false;
    },
  },
  components: {
    Icon,
    successSVG: () => import('@/assets/icons/check.svg'),
    errorSVG: () => import('@/assets/icons/close.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/notification-bar.scss"></style>
