<template>
  <div class="side-bar">
    <div class="profile  hide-xs">
      <div class="row">
        <div class="col-24">
          <router-link :to="{ name: 'Home', params: { lang: $i18n.locale } }">
            <Logo class="pl-m-less  pl-sm-0  hide-sm">
              <LogoSVG class="color-brand-blue"></LogoSVG>
            </Logo>
            <Logo class="pl-m-less  pl-sm-0  hide-sm-and-up  -max-width-65-percent  -margin-auto">
              <img :src="require(`@/assets/taskforce-avatar.png`)" alt="">
            </Logo>
          </router-link>
        </div>
      </div>
    </div>
    <component :is="`${role}Nav`" :status="status" :groups="groups"></component>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Tag from '@/components/atoms/Tag.vue';
import Logo from '@/components/atoms/Logo.vue';
import LogoSVG from '@/assets/logo.svg';

export default {
  name: 'SideBar',
  computed: {
    ...mapState([
      'usermeta',
      'role',
      'group',
      'status',
    ]),

    groups() {
      return (Array.isArray(this.group)) ? this.group : [this.group];
    },
  },
  components: {
    Tag,
    Logo,
    LogoSVG,
    managerNav: () => import(/* webpackChunkName: "user" */ '@/components/elements/NavManager.vue'),
    adminNav: () => import(/* webpackChunkName: "admin" */ '@/components/elements/NavAdmin.vue'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/side-bar.scss"></style>
