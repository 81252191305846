<template>
  <header class="header-bar">
    <div class="row  display-flex  justify-content-end" v-if="!$matchMedia.xs">
      <div class="col-24  text-right  valign-middle">
        <nav class="main-nav">
          <div class="pos-relative  help">
            <FlyoutTrigger flyoutEl="helpFlyout">
              <Icon class="mr-xxs  -size-16  -outlined  -cube">
                <HelpSVG></HelpSVG>
              </Icon>
            </FlyoutTrigger>
            <FlyoutNav class="mt-xxs  mr-xxs" ref="helpFlyout">
              <div class="_heading">
                {{ $t('navigations.help.helpHeadline') }}
              </div>
              <a :href="this.$t('navigations.help.newsLink')" target="_blank">
                {{ $t('navigations.help.newsText') }}
              </a>
              <hr class="color-blue-100  mb-0  mt-0">
              <div class="_heading">
                {{ $t('navigations.help.legalHeadline') }}
              </div>
              <a :href="this.$t('navigations.help.agbLink')" target="_blank">
                {{ $t('navigations.help.agbText') }}
              </a>
              <a :href="this.$t('navigations.help.privacyLink')" target="_blank">
                {{ $t('navigations.help.privacyText') }}
              </a>
              <a :href="this.$t('navigations.help.imprintLink')" target="_blank">
                {{ $t('navigations.help.imprintText') }}
              </a>
              <hr class="color-blue-100  mb-0  mt-0">
              <div class="_heading">
                {{ $t('navigations.help.discoverHeadline') }}
              </div>
              <a class="-orange" target="_blank" :href="this.$t('navigations.help.discoverLink')">
                <Icon class="-size-16  mr-xxs">
                  <LaunchSVG></LaunchSVG>
                </Icon>
                {{ $t('navigations.help.discoverText') }}
              </a>
            </FlyoutNav>
          </div>
          <div class="pos-relative  account">
            <FlyoutTrigger flyoutEl="profileFlyout">
              <Avatar class="-cube" v-if="userAvatar">
                <img :src="userAvatar" alt="">
              </Avatar>
              <Icon v-else class="-size-16  -outlined  -cube">
                <AccountSVG></AccountSVG>
              </Icon>
            </FlyoutTrigger>
            <FlyoutNav class="mt-xxs" ref="profileFlyout">
              <div class="_heading">
                {{ $t('navigations.profile.languages.headline') }}
              </div>
              <a href="" v-for="lang in langs" :key="lang"
                 :class="{ 'is-active': $i18n.locale === lang }"
                 @click.prevent="changeLocale(lang)">
                {{ $t(`navigations.profile.languages.${lang}`) }}
                <Icon v-if="$i18n.locale === lang" class="-size-18">
                  <CheckSVG></CheckSVG>
                </Icon>
              </a>
              <hr class="color-blue-100  mb-0  mt-0">
              <a @click="handleLogout" class="-orange">
                <Icon class="-size-16  mr-xxs">
                  <LogoutSVG></LogoutSVG>
                </Icon>
                {{ $t('navigations.profile.logout') }}
              </a>
            </FlyoutNav>
          </div>
        </nav>
      </div>
    </div>
    <div class="row  display-flex  align-items-center" v-if="$matchMedia.xs">
      <div class="col-12">
        <router-link :to="{ name: 'Home', params: { lang: $i18n.locale }}">
          <Logo class="pl-m-less  pl-sm-0">
            <LogoSVG class="color-brand-blue"></LogoSVG>
          </Logo>
        </router-link>
      </div>
      <div class="col-12  pos-relative">
        <HamburgerMenu></HamburgerMenu>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import Icon from '@/components/atoms/Icon.vue';
import Avatar from '@/components/atoms/Avatar.vue';
import HelpSVG from '@/assets/icons/help.svg';
import CheckSVG from '@/assets/icons/check.svg';
import LogoutSVG from '@/assets/icons/logout.svg';
import AccountSVG from '@/assets/icons/account.svg';
import LaunchSVG from '@/assets/icons/launch.svg';
import FlyoutTrigger from '@/components/elements/FlyoutTrigger.vue';
import FlyoutNav from '@/components/elements/FlyoutNav.vue';
import { LANGS } from '@/constants';

export default {
  name: 'HeaderBar',
  computed: {
    ...mapGetters([
      'loggedIn',
    ]),
    ...mapState([
      'avatar',
    ]),
    userAvatar() {
      // eslint-disable-next-line global-require
      return this.avatar;
    },
    langs() {
      return LANGS;
    },
  },
  methods: {
    ...mapActions([
      'logout',
    ]),

    handleLogout() {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'Login', params: { lang: this.$i18n.locale } });
        });
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push(`/${locale}${this.$router.currentRoute.path.substr(3)}`);
    },
  },
  mounted() {
    this.$store.dispatch('fetchAvatar');
  },
  components: {
    Icon,
    Avatar,
    HelpSVG,
    CheckSVG,
    LogoutSVG,
    LaunchSVG,
    FlyoutTrigger,
    FlyoutNav,
    AccountSVG,
    Logo: () => import('@/components/atoms/Logo.vue'),
    HamburgerMenu: () => import('@/components/elements/HamburgerMenu.vue'),
    LogoSVG: () => import('@/assets/logo.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/header-bar.scss"></style>
